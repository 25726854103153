<template>
  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
    <div class="a4" ref="printPaperRef" id="printPaperRef">
      <div class="page-header">
        <div>
          <img :src="require('@/assets/logo.svg')" />
        </div>
        <div>
          <span class="company">海南小紫医疗科技有限公司</span>
        </div>
      </div>
      <div class="title1">移机服务合同</div>
      <table border="1" class="dashed">
        <tr>
          <td colspan="2">甲方（服务接收方）：</td>
          <td colspan="3">{{ contractData.partA.name }}</td>
          <td colspan="3">最终用户：{{ contractData.partA.finalCustomerName || '' }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partA.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partA.address }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partA.telephone }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partA.fax }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="2">乙方（服务提供方）：</td>
          <td colspan="6">{{ contractData.partB.name }}</td>
        </tr>
        <tr>
          <td colspan="8">法人代表：{{ contractData.partB.legalPerson || '' }}</td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;地址：{{ contractData.partB.address || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;电话：{{ contractData.partB.telephone || '' }}</td>
          <td colspan="3"></td>
        </tr>
        <tr>
          <td colspan="5">&emsp;&emsp;传真：{{ contractData.partB.fax || '' }}</td>
          <td colspan="3"></td>
        </tr>
      </table>
      <div class="gap-top-2em indent-2em">
        甲乙双方经过平等协商，达成本保养服务合同（以下简称“合同”），合同内容包括具体条款、附件及合同中提及的其他文件。
      </div>
      <div class="gap-top-2em title2">1.&emsp;服务内容：</div>
      <div class="indent-2em">双方经协商同意，由乙方为甲方以下设备改变安装位置提供拆机和再装机服务。</div>
      <table border="1" class="whole-node">
        <thead>
          <tr>
            <th style="width: 50px">序号</th>
            <th>设备名称</th>
            <th>设备型号</th>
            <th>设备序列号</th>
            <th>装机日期</th>
            <th>预计移机时间</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(d, index) in contractData.deviceList" :key="`device${index}`">
            <td>{{ index + 1 }}</td>
            <td>{{ d.deviceName }}</td>
            <td>{{ d.deviceModel }}</td>
            <td>{{ d.deviceSerialNumber }}</td>
            <td>{{ d.installationDate }}</td>
            <td>{{ d.planVisitDate }}</td>
          </tr>
        </tbody>
      </table>
      <div class="indent-2em">
        乙方根据本合同为甲方客户以上设备提供拆机搬运服务一次。乙方负责拆卸，搬运到甲方指定位置。移机过程中非乙方人员原因造成的任何损坏，乙方不承担任何赔偿责任。乙方负责将设备所有部件拆机后搬运到指定位置，并安装完成达到使用条件，完成后签字验收（甲方指定签字验收人为：{{ contractData.signatory || '____' }}
        。如变更人员的，甲方应提前3个工作日书面告知乙方）。
        拆机所涉及的运输及其费用概由乙方负责，甲方负责现场的协调和沟通。
      </div>
      <div class="gap-top-2em title2">2.&emsp;合同总价款：</div>
      <div class="indent-2em">
        本合同总价（人民币）：{{ contractData.totalAmount || '____' }} 元&emsp;&ensp;&emsp;（大写）：{{ contractData.chineseTotalAmount || '____' }}。
      </div>
      <div class="indent-2em">
        以上价款为含税，税率为 {{ contractData.taxRate || '____' }}，如因国家相关规定导致税率产生变化，则以付款时适用的税率为准。
      </div>
      <div class="gap-top-2em title2">3.&emsp;付款方式及发票：</div>
      <div class="indent-2em">3.1&ensp;经双方协商，采取以下方式付款：</div>
      <div v-if="contractData.paymentList.length === 1 && !contractData.afterServeToSecondPartyPayDay && !contractData.afterServeToSecondPartyPayMoney" class="indent-2em">
        合同签署后 {{
          contractData.paymentDays || '__'
        }} 日内一次性支付全部价款。甲方应当按照合同约定将合同款项支付至交付乙方指定账户。
      </div>
      <template v-else>
        <div class="indent-2em">
          甲方按照如下约定分 {{ contractData.paymentList.length ? contractData.paymentList.length + 1 : '__' }} 期向乙方支付合同款项：
        </div>
        <div v-for="(payment, index) in contractData.paymentList" class="indent-2em" :key="index">
          第{{ payment.index }}期：在{{ payment.paymentDateArray[0] || ' ____' }}年{{ payment.paymentDateArray[1] || '__' }}月{{
            payment.paymentDateArray[2] || '__'
          }}日前支付人民币{{ payment.paymentAmount || ' ______ ' }}元；
        </div>
        <div v-if="contractData.paymentList.length" class="indent-2em" >
          第{{ numberTranToCN(contractData.paymentList.length + 1) }}期：甲方在服务完成后 {{contractData.afterServeToSecondPartyPayDay || '__'}} 日内向乙方付清人民币 {{ contractData.afterServeToSecondPartyPayMoney || '____' }} 元；
        </div>
      </template>
      <div class="indent-2em">3.2&ensp;乙方指定账户信息如下：</div>
      <div class="indent-2em title2">名 称：{{ contractData.partB.name }}</div>
      <div class="indent-2em title2">账 号：{{ contractData.bankAccount }}</div>
      <div class="indent-2em title2">开户行：{{ contractData.bankName }}</div>
      <div class="indent-2em">3.3&ensp;按照如下方式开具发票：</div>
      <div class="indent-2em">
        乙方每收到甲方支付的一笔款项后向甲方开具合规有效的等值发票（发票类型为：{{ contractData.invoiceTypeName }} ）。
      </div>
      <div class="indent-2em">
        甲方在收到乙方开具的合规有效的等值发票后（发票类型为：{{
          contractData.invoiceTypeName
        }}
        ）按照双方约定向乙方支付货款。
      </div>
      <div class="gap-top-2em title2">4.&emsp;乙方的责任：</div>
      <div class="indent-2em">乙方已进行过现场踏勘，确定具有搬运条件</div>
      <div class="indent-2em">
        <span style="position: absolute">4.1&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          乙方在收到甲方款项后按双方约定的日期进行拆装机服务，并在7天内完成。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">4.2&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          乙方负责该设备的系统分离使其处于可搬运状态；同时负责此设备拆机后安全运输到甲方指定位置，并进行安装调试使其达到临床使用标准。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">4.3&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">一旦设备在甲方选定的位置运输，运输期间若设备因乙方原因出现人为损伤，由乙方承担责任。</div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">4.4&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          进场后若无法在预计的时间内完成的，乙方应事立即通知并说明理由，双方另行确认合理的展期。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">4.5&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          乙方应对拆卸搬运期间自身人员的人身作业安全负责。
        </div>
      </div>
      <div class="gap-top-2em title2">5.&emsp;甲方的责任：</div>
      <div class="indent-2em">
        <span style="position: absolute">5.1&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          如具体实施时间有变动，甲方有义务在第一时间通知乙方，双方另行商议移机时间
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.2&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          乙方在提供服务期间所提供的信息，甲方应该保密。乙方对该信息拥有所有权，未经乙方书面同意，甲方不得擅自使用或泄露 给任何第三方或社会公众。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.3&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          按本协议的约定足额付款。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.4&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          甲方提供移机时必需的环境。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.5&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          本次合同服务相关的化学品（如酒精，高压油等）以及产生的相关废物，甲方应按照国家相关法律法规要求进行管理和处置。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.6&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          甲方保证拆机所需的恰当的条件包括但不限于相应的水、电源等。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">5.7&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          在本合同服务过程中，甲方应当随员监督，若发现乙方人员故意或重大过失损害设备的，应要求乙方改正。移机完成后，甲方应乙方的的要求及时进行验收，若已经验收或者逾期【3】个工作日不验收或一经使用（以先到时间为准），则视为移机验收合格。
        </div>
      </div>
      <div class="gap-top-2em title2">6.&emsp;违约责任：</div>
      <div class="indent-2em">
        <span style="position: absolute">6.1&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          除不可抗力或乙方不可能控制的因素导致的情形外，因为乙方无故不履行合同或者履行合同不符合约定给甲方造成损失的，乙方负责赔偿直接损失，该损失赔偿额最高不超过本合同签署时双方约定的甲方应支付给乙方的设备当期服务合同价款。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.2&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          如因甲方或其代表使用不当（如违反操作手册所载规程操作）等原因迫使乙方额外提供劳务或更换备件，乙方有权要求甲方承担因此发生的相关费用。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.3&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          如因非乙方的原因不再履行合同的，乙方有权要求甲方承担违约责任，违约金为合同总金额的20%，同时需支付已完成工作的款项支付，如造成乙方其他损失的，由甲方继续承担赔偿责任。若甲方确有理由不移机需要解除或终止合同的，应当事先【7】个工作日通知乙方，乙方有权要其甲方支付其已经支付/付出的相关费用、成本等。
        </div>
      </div>
      <div class="indent-2em">
        <span style="position: absolute">6.4&ensp;</span>
        <div style="margin-left: 2em; display: inline-block">
          甲方应当按照约定支付合同费用，逾期支付的，每逾期一日，按照合同总金额1‰支付滞纳金，超过7日仍未付款的，乙方有权暂停或不提供服务，因该原因造成的甲方损失由其自担，不视为乙方违约。
        </div>
      </div>
      <div class="gap-top-2em title2">7.&emsp;争议解决及法律适用：</div>
      <div class="indent-2em">双方应本着友好协商的原则解决争议。如协商不成，应提交乙方主要经营地法院诉讼解决。</div>
      <div class="gap-top-2em title2">8.&emsp;其他：</div>
      <div class="indent-2em">
        本合同经双方签字盖章生效，一式两份，甲方执一份，乙方执一份，双方所执签字盖章合同具有同等法律效力。
      </div>
      <div class="indent-2em">协议附件清单：（可删除没有的部分）。</div>
      <div v-if="!contractData.attachmentList.length" class="indent-2em">无</div>
      <div v-else v-for="(annex, index) in contractData.attachmentList" class="indent-2em" :key="`attach${index}`">
        {{ `${index + 1}、${annex.businessTypeDesc || '/'}` }}
      </div>
      <div class="indent-2em">本协议的附件是协议的有机组成部分，具有补充、解释或修改协议的作用，对双方有约束力</div>
      <table border="1" class="gap-top-2em dashed whole-node">
        <tr height="150">
          <td class="title2">
            甲&emsp;&emsp;方：
            <br />
            （盖章）
          </td>
          <td></td>
          <td class="title2">
            乙&emsp;&emsp;方：
            <br />
            （盖章）
          </td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">
            授权代表：
            <br />
            （签字）
          </td>
          <td></td>
          <td class="title2">
            授权代表：
            <br />
            （签字）
          </td>
          <td></td>
        </tr>
        <tr height="150">
          <td class="title2">日期：</td>
          <td></td>
          <td class="title2">日期：</td>
          <td></td>
        </tr>
      </table>
    </div>
    <div style="text-align: center">
      <a-button @click="handlePrint">打印</a-button>
    </div>
  </div>
</template>
<script>
import {numberTranToCN } from '@/utils/chinesePrice';

export default {
  name: 'relocationContract',
  props: {
    contractData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      numberTranToCN,
    };
  },
  methods: {
    handlePrint () {
      const style
        = '@page {margin:6mm 20mm;size: A4;} @charset "UTF-8";body {font-family: "宋体";}.a4 {width: 210mm;background-color: #fff;}.a4 .page-header {display: flex;justify-content: space-between;align-items: flex-end;font-size: 12px;padding-bottom: 6px;border-bottom: 1px solid #000;}.a4 .page-header img {width: 62%;}.a4 .page-header .company {display: inline-block;width: 16em;color: #808080;}.a4 div {line-height: 2em;}.a4 .gap-top-1em {margin-top: 1em;}.a4 .gap-top-2em {margin-top: 2em;}.a4 .title2 {font-weight: bolder;}.a4 .indent-2em {margin-left: 2em;}.a4 .title1 {text-align: center;line-height: 60px;font-size: 22px;letter-spacing: .5em;}.a4 table {width: 99%;table-layout: fixed;border-collapse: collapse;}.a4 table tr {page-break-inside: avoid;}.a4 table th {padding: 8px;font-weight: 500;}.a4 table td {padding: 8px;word-break: break-all;}.a4 table.dashed {border: 1px dashed #c8c8c8;}.a4 table.dashed td {border: 1px dashed #c8c8c8;}';

      this.$print({
        printable: 'printPaperRef',
        type: 'html',
        targetStyles: ['*'],
        style,
        scanStyles: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.a4 {
  width: 210mm;
  background-color: #fff;

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 12px;
    padding-bottom: 6px;
    border-bottom: 1px solid #000;

    img {
      width: 62%;
    }

    .company {
      display: inline-block;
      width: 16em;
      color: #808080;
    }
  }

  div {
    line-height: 2em;
  }

  .gap-top-1em {
    margin-top: 1em;
  }

  .gap-top-2em {
    margin-top: 2em;
  }

  .title2 {
    font-weight: bolder;
  }

  .indent-2em {
    margin-left: 2em;
  }

  .title1 {
    text-align: center;
    line-height: 32px;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5em;
  }

  table {
    width: 99%;
    table-layout: fixed;
    border-collapse: collapse;

    tr {
      page-break-inside: avoid;
    }

    th {
      padding: 8px;
      font-weight: 500;
    }

    td {
      padding: 8px;
      word-break: break-all;
    }
  }

  table.dashed {
    border: 1px dashed #c8c8c8;

    td {
      border: 1px dashed #c8c8c8;
    }
  }
}
</style>
